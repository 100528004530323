<template>
    <div class="contacts-page_wrapper">
        <img class="contacts-page_img" src="@/assets/images/contacts.png"/>
        <div>
            <Breadcrumbs
                :items="items"
            />
            <h1 class="contacts-page_header">{{ $t('contacts') }}</h1>
            <h2 class="contacts-page_sub-title">{{ $t('contactSubTitle') }}</h2>
            <p class="contacts-page_text">{{ $t('contactText1') }}</p>
            <p class="contacts-page_text" v-html="$t('contactText2')"></p>
            <p class="contacts-page_text" v-html="$t('contactText3')"></p>
            <p class="contacts-page_text" v-html="$t('contactText4')"></p>
        </div>
    </div>
</template>
<script>
import Breadcrumbs from '@/components/breadcrumbs'

export default {
    components: {
        Breadcrumbs
    },

    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'contacts',
                href: '#',
            }
        ]
    })
}
</script>
<style lang="scss" scoped>
.contacts-page {
    &_wrapper {
        display: flex;

        @media(max-width: 850px) {
            flex-direction: column;
        }
    }
    &_img {
        margin-right: 40px;

        @media(max-width: 850px) {
            margin: 0 0 40px;
        }
    }
    &_header {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
    }
    &_sub-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 34px;
        margin: 40px 0 15px;
    }
    &_text {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}
</style>